import NextLink from "next/link";
import s from "styled-components";

import Brand from "./brand";
import Facebook from "./social/facebook";
import Instagram from "./social/instagram";
import Twitter from "./social/twitter";

const Footer = () => (
  <Container>
    <Links>
      <Group>
        <Name>Website</Name>
        <NextLink passHref href="/" legacyBehavior>
          <Link>Home</Link>
        </NextLink>
        <NextLink passHref href="/directories" legacyBehavior>
          <Link>Directories</Link>
        </NextLink>
        <NextLink passHref href="/features" legacyBehavior>
          <Link>Features</Link>
        </NextLink>
        <NextLink passHref href="/pricing" legacyBehavior>
          <Link>Pricing</Link>
        </NextLink>
        <NextLink passHref href="/about" legacyBehavior>
          <Link>About</Link>
        </NextLink>
      </Group>
      <Group>
        <Name>Information</Name>
        <NextLink passHref href="/faq" legacyBehavior>
          <Link>FAQ</Link>
        </NextLink>
        <NextLink passHref href="/terms" legacyBehavior>
          <Link>Terms &amp; Conditions</Link>
        </NextLink>
        <NextLink passHref href="/privacy" legacyBehavior>
          <Link>Privacy Policy</Link>
        </NextLink>
        <NextLink passHref href="/cookie" legacyBehavior>
          <Link>Cookie Policy</Link>
        </NextLink>
      </Group>
      <Group>
        <Name>Contact Us</Name>
        <Link href="mailto:support@publitdesign.com">
          support@publitdesign.com
        </Link>
      </Group>
      <Social>
        <Name social>Connect</Name>
        <SocialLinks>
          <SocialLink
            href="https://facebook.com/publitdesign"
            rel="noopener noreferrer"
            target="_blank"
            aria-label="Facebook"
          >
            <Facebook />
          </SocialLink>
          <SocialLink
            href="https://twitter.com/publitdesign"
            rel="noopener noreferrer"
            target="_blank"
            aria-label="Twitter"
          >
            <Twitter />
          </SocialLink>
          <SocialLink
            href="https://instagram.com/publitdesign"
            rel="noopener noreferrer"
            target="_blank"
            aria-label="Instagram"
          >
            <Instagram />
          </SocialLink>
        </SocialLinks>
      </Social>
    </Links>
    <Masthead>
      <BrandContainer>
        <Brand size="small" dark />
      </BrandContainer>
      <Legal>Publit is a product of Midwest Design Lab.</Legal>
      <Legal>
        {`© ${new Date().getFullYear()}`} Midwest Design Lab LLC. All Rights
        Reserved.
      </Legal>
    </Masthead>
  </Container>
);

export default Footer;

const Container = s.footer`
${(props) => props.theme.css.contentWidth};
padding: 50px 0;
box-sizing: border-box;

font-size: 16px;
line-height: 22px;
color: black;

// override from css.contentWidth
@media (max-width: 550px) {
  margin: 0 20px;
}
`;

const Links = s.div`
display: flex;

@media (max-width: 740px) {
  display: block;
  text-align: center;
}
`;

const Group = s.div`
margin-right: 50px;

@media (max-width: 740px) {
  margin-right: 0;
  margin-bottom: 40px;
}
`;

const Name = s.span`
display: block;
font-weight: bold;
margin-bottom: ${(props) => (props.social ? "0" : "10px")};
text-align: ${(props) => (props.social ? "center" : "inherit")};
color: var(--color-blue);
`;

const Link = s.a`
display: block;
margin-bottom: 10px;
text-decoration: none;
color: inherit;

&:hover {
  color: var(--color-blue);
}
`;

const Social = s.div`
margin-left: auto;
margin-right: 0;
`;

const SocialLinks = s.div`
display: flex;
align-items: center;
justify-content: center;
`;

const SocialLink = s.a`
padding: 10px;
&:not(:last-child) {
  margin-right: 10px;
}

.fill { fill: #000; }

&:hover .fill { fill: var(--color-blue); }
`;

const Masthead = s.div`
text-align: center;

@media (max-width: 740px) {
  margin-top: 30px;
}
`;

const BrandContainer = s.div`
display: flex;
justify-content: center;
margin: 1rem 0;
`;

const Legal = s.p`
margin: 10px 0;
`;
